import Vue from 'vue'
import Vuetify from 'vuetify/lib'
/* import 'vuetify/src/stylus/app.styl' */

Vue.use(Vuetify)

/*
Vue.use(Vuetify, {
  iconfont: 'md',
})
*/